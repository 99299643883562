import { Tooltip, HStack, IconButton, useDisclosure } from "@chakra-ui/react"
import { FiExternalLink, FiArchive, FiTrash2, FiThumbsDown, FiThumbsUp } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { editStart } from "../../redux/CreateDoc/createDocSlice"
import { useNavigate } from 'react-router-dom'
import DeleteConfirmation from "../DeleteConfirmation"
import ArchiveConfirmation from "../ArchiveConfirmation"
import { deleteDocument, updateDocument, getDocuments } from "../../redux/Document/documentCalls"

export default function OpenCellAdmin({ row, table, from = 'tables' }) {
    const meta = table.options.meta
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
    const { isOpen: isArchiveOpen, onOpen: onArchiveOpen, onClose: onArchiveClose } = useDisclosure()
    const { account_id } = useSelector((state) => state.account)

    function handleOpenDocument() {
        dispatch(editStart(row.original))
        navigate('/edit/options')
    }

    function handleArchiveDocument() {
        var isActive = 'inactive'
        // HR - admin user can archive documents from history tables and unarchive documents from the settings 'manage documents' tab
        if (from === 'settings') {
            isActive = 'active'
        }
        const editPayload = { active: isActive, doc_number: row.original.doc_number }
        updateDocument(editPayload, dispatch)
        meta?.removeRow(row.index);
        onArchiveClose()
        // HR - refetch the current document arrays to update all tables
        getDocuments(account_id, dispatch)
    }

    function handleToggleStatus(status) {
        const editPayload = { quote_status: status, doc_number: row.original.doc_number }
        updateDocument(editPayload, dispatch)
        getDocuments(account_id, dispatch)
    }

    function handleDeleteDocument() {
        deleteDocument(row.original._id, dispatch)
        meta?.removeRow(row.index);
        onDeleteClose()
    }

    return (
        <>
            <HStack spacing={'1rem'}>
                <Tooltip label='Open' fontSize='sm'>
                    <IconButton onClick={handleOpenDocument} variant={"outline"} borderColor={"rgb(237, 137, 54)"} icon={<FiExternalLink color="rgb(237, 137, 54)" />} />
                </Tooltip>
                {/* HR - provide quotes with buttons to toggle status as open or closed lost*/}
                {(row.original.type === "Quote" && row.original.quote_status === 'Active' && from !== 'settings') &&
                    <Tooltip label='Make Inactive' fontSize='sm'>
                        <IconButton onClick={() => handleToggleStatus('Inactive (Lost)')} variant={"outline"} borderColor={"rgb(187, 134, 252)"} icon={<FiThumbsDown color="rgb(187, 134, 252)" />} />
                    </Tooltip>
                }
                {(row.original.type === "Quote" && row.original.quote_status !== 'Active' && from !== 'settings') &&
                    <Tooltip label='Make Active' fontSize='sm'>
                        <IconButton onClick={() => handleToggleStatus('Active')} variant={"outline"} borderColor={"rgb(56, 161, 105)"} icon={<FiThumbsUp color="rgb(56, 161, 105)" />} />
                    </Tooltip>
                }
                <Tooltip label={from === 'settings' ? 'Unarchive' : 'Archive'} fontSize='sm'>
                    <IconButton onClick={onArchiveOpen} variant={"outline"} borderColor={"rgb(52, 152, 219)"} icon={<FiArchive color="rgb(52, 152, 219)" />} />
                </Tooltip>
                <Tooltip label='Delete' fontSize='sm'>
                    <IconButton onClick={onDeleteOpen} variant={"outline"} borderColor={"rgb(229, 62, 62)"} icon={<FiTrash2 color="rgb(229, 62, 62)" />} />
                </Tooltip>
            </HStack>
            <DeleteConfirmation type={'Document'} isOpen={isDeleteOpen} onOpen={onDeleteOpen} onClose={onDeleteClose} removeRow={handleDeleteDocument} />
            <ArchiveConfirmation isOpen={isArchiveOpen} onOpen={onArchiveOpen} onClose={onArchiveClose} removeRow={handleArchiveDocument} mode={from === 'settings' ? 'Unarchive' : 'Archive'} />
        </>
    )
}
