import { Flex, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react"
import TanTable from "../components/TanStackTable/TanTable"
import quoteHistoryColumns from "../components/TanStackTable/Columns/History/quoteHistoryColumns"
import AddCell from "../components/TanStackTable/AddCell"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getDocuments } from "../redux/Document/documentCalls"
import quoteHistoryColumnsAdmin from "../components/TanStackTable/Columns/Admin/quoteHistoryColumnsAdmin"

export default function QuoteHistory() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const { quotes } = useSelector((state) => state.documents)
    const { account_id } = useSelector((state) => state.account)
    const { user_role } = useSelector((state) => state.auth)
    var columns = quoteHistoryColumns
    
    // HR - if user is admin or owner, they will be able to archive and delete quotes
    if (user_role === "owner" || user_role === "admin") {
        columns = quoteHistoryColumnsAdmin
    }

    // HR - we make call to fetch document information which populates the table
    useEffect(() => {
        getDocuments(account_id, dispatch)
    }, [dispatch])

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={"2rem"}
        >
            <VStack w={'100%'}>
                {/* HR - AddCell displays a button w/ dropdown menu that links to either "/create/quote" or "create/purchaseorder" */}
                <AddCell type="quote_history" openModal={onOpen} />
                {/* HR - history data is currently static, each row has cell to eventually view/edit document */}
                <TanTable columns={columns} defaultData={quotes} type="history" />
            </VStack>
        </Flex>
    )
}