import SortableList from '../Sortable/SortableList'
import { useState, memo, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { StyleSheet } from "@react-pdf/renderer";
import MyDocument from '../PreviewPDF/MyDocument';
import { useDispatch, useSelector } from 'react-redux';
import { customizeSuccess } from '../../redux/CreateDoc/createDocSlice';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import companyLogo2 from "../../images/default_logo.png"
import tablesHelperPDF from '../../helper/tableHelperPDF';

// HR - Memoizing the MyDOcument component reduces unnecessary rerendering; now only rerenders on state change (items)
const MemoDoc = memo(MyDocument);
const drawerWidth = 330;

const styles = StyleSheet.create({
    formBg: {
        width: '60vw'
    },
    formSm: {
        width: '75vw'
    },
    drawerOpen: {
        marginLeft: drawerWidth
    },
    drawerClosed: {
        marginLeft: 0
    }
});

export default function CustomizePDF({ type, mode }) {
    // HR - viewPDF toggles whether the PDF is visible to user
    const [viewPDF, setViewPDF] = useState(true)
    const [items, setItems] = useState([])
    const [currentLogo, setCurrentLogo] = useState(companyLogo2)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()

    // HR - I tried to move this information down to PDFDoc & MyDocument but redux cannot function there, the researched solution is to pass this state info down
    const { doc_number } = useSelector((state) => state.createDoc)
    const { date } = useSelector((state) => state.createDoc)
    const { contact } = useSelector((state) => state.createDoc)
    const { products } = useSelector((state) => state.createDoc)
    const { tables } = useSelector((state) => state.createDoc)
    const { content } = useSelector((state) => state.createDoc)
    const { total } = useSelector((state) => state.createDoc)
    const { terms_content } = useSelector((state) => state.createDoc)
    const { shipping_content } = useSelector((state) => state.createDoc)
    const { discounts_enabled } = useSelector((state) => state.createDoc)
    const { user_name } = useSelector((state) => state.auth)
    const { account_id } = useSelector((state) => state.account)
    const { account_name } = useSelector((state) => state.account)
    const { address_1 } = useSelector((state) => state.account)
    const { city } = useSelector((state) => state.account)
    const { state } = useSelector((state) => state.account)
    const { zip } = useSelector((state) => state.account)
    const { currency } = useSelector((state) => state.account)
    const { account_phone } = useSelector((state) => state.account)
    const { account_fax } = useSelector((state) => state.account)
    const { account_email } = useSelector((state) => state.account)
    const { account_logo } = useSelector((state) => state.account)
    const { default_valid_for } = useSelector((state) => state.account)
    const { default_terms_text } = useSelector((state) => state.account)
    const { resources } = useSelector((state) => state.resources)

    useEffect(() => {
        dispatch(customizeSuccess({ items: items, type: type }))
    }, [items])

    // HR - if an account logo is assigned, get the resource_url from the resources redux state to render the correct image on screen
    useEffect(() => {
        if (account_logo) {
            var logoResource = resources.find((resource) => {
                return resource._id === account_logo
            })
            // handleLogoDownload(logoResource.resource_url, logoResource.title)
            setCurrentLogo(logoResource.resource_url)
        }
    }, [account_logo, resources])


    useEffect(() => {
        var contentArray = []
        if (content.length === 0 && type === "Quote") {
            // HR - if new quote, add default sortable item with "Dear Contact," which can be deleted and revised
            contentArray.push({
                data: `Dear ${contact.name},`,
                id: nanoid(21),
                style: "Default Text",
                type: "string"
            })
            contentArray.push({
                data: "Price Breakdown",
                id: nanoid(21),
                style: "Subtitle",
                type: "string"
            })
            // HR - add tables as sortable items
            const properTables = tablesHelperPDF(tables, products)
            Object.keys(tables).forEach((tableKey) => {
                contentArray.push({
                    data: "Table " + tableKey,
                    tableData: properTables[tableKey],
                    id: nanoid(21),
                    style: "table",
                    type: "table"
                })
            })
            setItems(contentArray)
        } else if (content.length === 0 && type === "PO") {
            contentArray.push({
                data: "Price Breakdown",
                id: nanoid(21),
                style: "Subtitle",
                type: "string"
            })
            // HR - add tables as sortable items
            const properTables = tablesHelperPDF(tables, products)
            Object.keys(tables).forEach((tableKey) => {
                contentArray.push({
                    data: "Table " + tableKey,
                    tableData: properTables[tableKey],
                    id: nanoid(21),
                    style: "table",
                    type: "table"
                })
            })
            setItems(contentArray)
        } else {
            // HR - contentArray contains updated tables as items
            const properTables = tablesHelperPDF(tables, products)
            Object.keys(tables).forEach((tableKey) => {
                contentArray.push({
                    data: "Table " + tableKey,
                    tableData: properTables[tableKey],
                    id: nanoid(21),
                    style: "table",
                    type: "table"
                })
            })
            var newContent = content.map((contentItem) => {
                // HR - if type is not table, simply return the original item
                if (contentItem.type !== "table") {
                    return contentItem
                } else {
                    // HR - if type table, return and pop first table from contentArray
                    if (contentArray.length > 0) {
                        return contentArray.shift()
                    }
                }
            })
            // HR - if any items left in contentArray, push them to newContent array
            if (contentArray.length > 0) {
                contentArray.forEach(contentArrayItem => {
                    newContent.push(contentArrayItem)
                });
            }
            setItems(newContent)
        }
    }, [])

    useEffect(() => {

    }, [])

    function changeViewPDF() {
        setViewPDF(!viewPDF)
    }

    return (
        <Flex direction={'row'} justifyContent={'center'} height={'75vh'} w={'70%'} id="flexy" style={isOpen ? styles.drawerOpen : styles.drawerClosed}>
            {mode !== 'view' && <Box flex={1}>
                {/* HR - SortabelList is where users can add, edit, rearrange PDF content (DND) */}
                <SortableList items={items} setItems={setItems} changeViewPDF={changeViewPDF} type={type} isOpen={isOpen} onClose={onClose} onOpen={onOpen} mode={mode} />
            </Box>}
            {/* HR - this is where PDF lives */}
            {/* HR - PDFViewer comes from PDFViewer library and is essential for displaying PDFs */}
            {
                viewPDF &&
                <Flex flex={1} justifyContent={'center'} id="test2">
                    <PDFViewer height={'100%'} width={'100%'} showToolbar={false}>
                        <MemoDoc type={type} doc_number={doc_number} user_name={user_name} account_id={account_id} account_name={account_name} date={date} aPhone={account_phone} aEmail={account_email}
                            aFax={account_fax} aAddress_1={address_1} aCity={city} aState={state} aZip={zip} cName={contact.company_name} cAddress_1={contact.address_1} cCity={contact.city}
                            cState={contact.state} cCountry={contact.country} cZip={contact.zip} cPhone={contact.phone} cEmail={contact.email} products={products} tables={tables} content={content}
                            total={total} terms_content={terms_content} shipping_content={shipping_content} discounts_enabled={discounts_enabled} logo={currentLogo} default_valid_for={default_valid_for}
                            default_terms_text={default_terms_text} currency={currency} />
                    </PDFViewer>
                </Flex>
            }
        </Flex>
    )
}