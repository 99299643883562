import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		user_id: null,
		email: null,
		user_name: null,
		user_initials: null,
		user_role: null,
		isFetching: false,
		error: false,
	},
	reducers: {
		loginStart: (state) => {
			state.isFetching = true;
		},
		loginSuccess: (state, action) => {
			state.user_id = action.payload.user_id;
			state.email = action.payload.email;
			// HR - if user has set a custom display name, set that to be the value of the username
			if (action.payload.display_name && action.payload.display_name.length > 0) {
				state.user_name = action.payload.display_name
				var matches = action.payload.display_name.match(/\b(\w)/g);
				var acronym = matches.join('');
			} else {
				state.user_name = action.payload.user_name;
				var matches = action.payload.user_name.match(/\b(\w)/g);
				var acronym = matches.join('');
			}
			state.user_initials = acronym;
			state.user_role = action.payload.user_role;
			state.isFetching = false;
		},
		loginFailure: (state) => {
			state.error = true;
			state.isFetching = false;
		},
		updateNameSuccess:(state, action) => {
			state.user_name = action.payload
			var matches = action.payload.match(/\b(\w)/g);
			var acronym = matches.join('');
			state.user_initials = acronym;
		},
		logout: (state) => {
			state.user_id = null;
			state.email = null;
			state.user_name = null;
			state.user_initials = null;
			state.user_role = null;
			state.isFetching = false;
			state.error = false;
		},
	},
});

export const { loginStart, loginSuccess, loginFailure, updateNameSuccess, logout } =
	authSlice.actions;

export default authSlice.reducer;