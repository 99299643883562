import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { VStack, HStack, Text, Image, Card, CardBody, Link, CardFooter, Container, Tooltip, IconButton } from "@chakra-ui/react"
import { FiExternalLink, FiTrash2 } from 'react-icons/fi'
import pdf_icon from "../images/pdf_icon.png"
import { deleteResource } from "../redux/Resource/resourceCalls";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function CarouselNew() {
    const { resources } = useSelector((state) => state.resources)
    const { account_logo } = useSelector((state) => state.account)
    const { account_terms } = useSelector((state) => state.account)
    const dispatch = useDispatch()

    function handleDeleteResource(resource_id) {
        deleteResource(resource_id, dispatch)
    }

    return (
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            //   deviceType={this.props.deviceType}
            deviceType={'desktop'}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-20-px"
        >
            {resources && resources.map((resource) => {
                var fileType = "unknown"
                if (resource.type) {
                    fileType = resource.type.split('/')[0]
                }

                return (
                    <Card w="15vw" h="fit-content" m='5rem' key={resource._id}>
                        <CardBody>
                            <VStack>
                                <HStack
                                    bg="white"
                                    alignContent="center"
                                    borderRadius={"1rem"}
                                    justifyContent="center"
                                    m={'auto'}
                                    mt={10}
                                >
                                    {fileType === "image" ?
                                        <Image
                                            src={resource.resource_url}
                                            borderRadius='lg'
                                            height={'25vh'}
                                        />
                                        :
                                        <Image
                                            src={pdf_icon}
                                            borderRadius='lg'
                                            height={'25vh'}
                                        />
                                    }
                                </HStack>
                            </VStack>
                        </CardBody>
                        <CardFooter>
                            <Container centerContent mt={'1.5rem'} mb={'1rem'}>
                                <Text fontSize="3xl" fontFamily="heading" fontWeight="light">{resource.title}</Text>
                                <HStack spacing={'2rem'} mt={'1rem'}>
                                    {(resource._id !== account_logo && resource._id !== account_terms) &&
                                        <Tooltip label='Delete' fontSize='sm'>
                                            <IconButton variant={"outline"} borderColor={"rgb(229, 62, 62)"} icon={<FiTrash2 color="rgb(229, 62, 62)" />} onClick={() => handleDeleteResource(resource._id)} />
                                        </Tooltip>
                                    }
                                    <Link href={resource.resource_url} isExternal>
                                        <Tooltip label='Open' fontSize='sm'>
                                            <IconButton variant={"outline"} borderColor={"rgb(237, 137, 54)"} icon={<FiExternalLink color="rgb(237, 137, 54)" />} />
                                        </Tooltip>
                                    </Link>
                                </HStack>
                            </Container>
                        </CardFooter>
                    </Card>
                )
            })}
        </Carousel >
    )
}