import { Box, IconButton, Stack, Icon } from "@chakra-ui/react";
import { useSortable, defaultAnimateLayoutChanges } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FiX, FiEdit } from "react-icons/fi";
import { RxDragHandleDots2 } from "react-icons/rx";
import { GrTable } from "react-icons/gr";
import { RiText, RiSpace } from "react-icons/ri";
import { PiTextAa } from "react-icons/pi";

const SortableItem = ({ id, item, onRemove, onUpdate, onTableUpdate, mode }) => {
    var drawObj = ""
    if (item) {
        if (item.type === "string" || item.type === "table" || item.type === "space") {
            // HR - if an item is a string, data is text
            drawObj = item.data
        } else if (item.type === "image") {
            //  HR - if item is an image, data is path
            drawObj = <img src={item.data} alt="pic here" style={{ width: 100, height: 100 }} />
        }
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({
        id: id,
        animateLayoutChanges: args => { defaultAnimateLayoutChanges({ isSorting: true }) }
    });

    const style = {
        transform: CSS.Translate.toString(transform), // use Translate instead of Transform to not scale transformations (large items stretch neighbors and vice versa)
        transition,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "10px",
        padding: "10px",
        margin: 10,
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        color: "black"
    };

    return (
        <Box ref={setNodeRef} style={style} {...attributes} >
            <Box style={{ width: "100%", padding: '10px', height: 'fit-content', display: 'flex', alignItems: 'center', position: 'relative' }}>
                <Box className="movebtn" {...listeners} style={{ flex: 0, marginRight: 5, padding: 5, borderRadius: 5 }} _hover={{ background: '#f5f5f5' }}>
                    <RxDragHandleDots2 />
                </Box>
                <Box style={{ flex: 1 }}>
                    {item.type === "string" && <Icon as={PiTextAa} mr={'1rem'} color={'red'}/>}
                    {item.type === "table" && <Icon as={GrTable} mr={'1rem'} color={'green'} />}
                    {item.type === "space" && <Icon as={RiSpace} mr={'1rem'} color={'blue'} />}
                    {drawObj}
                </Box>
                <Stack direction={'row'} style={{ flex: 0 }} >
                    {item.type !== "table" && <>
                        <IconButton onClick={() => onUpdate(item)} variant={"outline"} borderColor={"rgb(56, 161, 105)"} icon={<FiEdit color="rgb(56, 161, 105)" />} />
                        <IconButton onClick={() => onRemove(id)} variant={"outline"} borderColor={"rgb(229, 62, 62)"} icon={<FiX color="rgb(229, 62, 62)" />} />
                    </>}
                </Stack>
            </Box>
        </Box>
    );
};
export default SortableItem;
