import { Heading, Text, ListItem, UnorderedList } from "@chakra-ui/react";
import { useState } from "react";
// import { MdCheckCircle } from 'react-icons/md'
// import ImportProductsModal from "./ImportProductsModal";
// import InviteUsersModal from "./InviteUsersModal";
// import ImportContactsModal from "./ImportContactsModal";
import GoogleButton from "../GoogleButton";
import { useGoogleLogin } from "@react-oauth/google";
import { login } from "../../redux/Auth/authCalls";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function SetupForm() {
    // const { isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose } = useDisclosure()
    // const { isOpen: isProductsOpen, onOpen: onProductsOpen, onClose: onProductsClose } = useDisclosure()
    // const { isOpen: isContactsOpen, onOpen: onContactsOpen, onClose: onContactsClose } = useDisclosure()
    const [completedTasks, setCompletedTasks] = useState({ invite_users: false, initializeProducts: false, initializeContacts: false })
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const googLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            login(tokenResponse.access_token, dispatch)
            navigate("/")
        }
    })

    return (
        <>
            <Heading fontSize={"4xl"} textAlign={"center"}>Your account has been created! Please login to begin using RapidToolz</Heading>
            <GoogleButton onClickFunction={googLogin} phrase={"Login"} />
            <Text fontSize={"2xl"} textAlign={"center"}>Once inside your new account, check out the settings page to complete the following steps: </Text>
            <UnorderedList fontSize={"xl"}>
                <ListItem>Invite Users</ListItem>
                <ListItem>Define Payment Terms</ListItem>
                <ListItem>Choose Company Logo</ListItem>
                <ListItem>Upload Terms and Conditions</ListItem>
                <ListItem>Set Terms Defaults</ListItem>
                {/* <HStack>
                    {completedTasks.invite_users ? <ListIcon as={MdCheckCircle} color='green.500' /> : null}
                    <ListIcon as={TbMailShare} color={'blue.500'} />
                    <ListItem>Invite Users</ListItem>
                    <IconButton
                        isRound={true}
                        variant='ghost'
                        colorScheme='blue'
                        aria-label='Done'
                        fontSize={'1.5rem'}
                        icon={<TbMailShare />}
                        onClick={onInviteOpen}
                    />
                </HStack>
                <HStack>
                    {completedTasks.initializeProducts ? <ListIcon as={MdCheckCircle} color='green.500' /> : null}
                    <ListIcon as={TbTableImport} color={'blue.500'} />
                    <ListItem>Import Products</ListItem>
                    <IconButton
                        isRound={true}
                        variant='ghost'
                        colorScheme='blue'
                        aria-label='Done'
                        fontSize={'1.5rem'}
                        icon={<TbTableImport />}
                        onClick={onProductsOpen}
                    />
                </HStack>
                <HStack>
                    {completedTasks.initializeContacts ? <ListIcon as={MdCheckCircle} color='green.500' /> : null}
                    <ListIcon as={TbTableImport} color={'blue.500'} />
                    <ListItem>Import Contacts</ListItem>
                    <IconButton
                        isRound={true}
                        variant='ghost'
                        colorScheme='blue'
                        aria-label='Done'
                        fontSize={'1.5rem'}
                        icon={<TbTableImport />}
                        onClick={onContactsOpen}
                    />
                </HStack> */}
            </UnorderedList>
            {/* <InviteUsersModal isOpen={isInviteOpen} onClose={onInviteClose} setCompletedTasks={setCompletedTasks} completedTasks={completedTasks} />
            <ImportProductsModal isOpen={isProductsOpen} onClose={onProductsClose} setCompletedTasks={setCompletedTasks} completedTasks={completedTasks} />
            <ImportContactsModal isOpen={isContactsOpen} onClose={onContactsClose} setCompletedTasks={setCompletedTasks} completedTasks={completedTasks} /> */}
        </>
    )
}