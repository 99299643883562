import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react"
import TanTable from "./TanStackTable/TanTable"
import productSelectQuoteColumns from "./TanStackTable/Columns/Products/productSelectQuoteColumns"
import productSelectPOColumns from "./TanStackTable/Columns/Products/productSelectPOColumns"
import { useSelector, useDispatch } from "react-redux"
import { getProducts } from "../redux/Product/productCalls"
import { useEffect } from "react"

export default function ProductModal({ isOpen, onOpen, onClose, setProductRows, handleClick, type, setProductIndicies }) {
    const { products } = useSelector((state) => state.products)
    const dispatch = useDispatch()
    const { account_id } = useSelector((state) => state.account)
    const { product_indicies } = useSelector((state) => state.createDoc)

    // HR - function for converting product rowModels to an array of row data
    // we map through the selected rows and return "original" (data) of each
    function handleChangeProducts(rowModel) {
        var selectedIndicies = {}
        setProductRows(rowModel.map((row) => {
            // setProductIndicies(old => ({ ...old, [index]: true }))
            selectedIndicies[row.index] = true
            if (type === "PO") {
                return {
                    ...row.original,
                    quantity: 1,
                }
            } else {
                return {
                    ...row.original,
                    quantity: 1,
                }
            }
        }))
        setProductIndicies(selectedIndicies)
         // setProductIndicies(old => ({ ...old, [index]: true }))
    }

    useEffect(() => {
        getProducts(account_id, dispatch)
    }, [dispatch])

    var columns = null
    if (type === "PO") {
        columns = productSelectPOColumns
    } else { // HR - quotes/invoices without discounts
        columns = productSelectQuoteColumns
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign={"center"}>Products Table</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <TanTable columns={columns} defaultData={products} type="productModal" setModalRows={handleChangeProducts} selectedIndicies={product_indicies} />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={handleClick}>
                        Done
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}