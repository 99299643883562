import * as React from "react"
import { useState, useEffect } from "react"
import "../../styles/table.css"
import {
    useReactTable,
    sortingFns,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,

} from "@tanstack/react-table"
import { rankItem, compareItems } from "@tanstack/match-sorter-utils"
import { Button, Container, HStack, Input, Select, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import {
    FiChevronDown,
    FiChevronUp,
} from "react-icons/fi"
import DebouncedInput from "./DebouncedInput"
import Filter from "./Filter"

const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank
    })
    // Return if the item should be filtered in/out
    return itemRank.passed
}

// const fuzzySort = (rowA, rowB, columnId) => {
//     let dir = 0

//     // Only sort by rank if the column has ranking information
//     if (rowA.columnFiltersMeta[columnId]) {
//         dir = compareItems(
//             rowA.columnFiltersMeta[columnId]?.itemRank,
//             rowB.columnFiltersMeta[columnId]?.itemRank
//         )
//     }

//     // Provide an alphanumeric fallback for when the item ranks are equal
//     return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
// }

// HR - TanTable is different than TanFormTable in that is DOES have the global/column filters and it does NOT have add new/existing buttons
// TODO - make id a hidden column, cannot delete
export default function TanTable({ columns, defaultData, type, setModalRows = () => { }, selectedIndicies = {} }) {
    // {id: 'type', value: 'Quote'}
    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState("")
    const [rowSelection, setRowSelection] = useState({})
    const [data, setData] = useState(() => [...defaultData])
    const [originalData, setOriginalData] = useState(() => [...defaultData])
    const [editedRows, setEditedRows] = useState({})

    // HR - this is necessary to fix bug where searching on page 2 does not show results from page 1
    useEffect(() => {
        table.setPageIndex(0)
    }, [columnFilters, globalFilter])


    useEffect(() => {
        setData(() => [...defaultData])
        setOriginalData(() => [...defaultData])
    }, [defaultData])


    useEffect(() => {
        if (Object.keys(selectedIndicies).length > 0) {
            setRowSelection(selectedIndicies)
        }
    }, [])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter
        },
        state: {
            rowSelection,
            columnFilters,
            globalFilter,
        },
        enableRowSelection: true,
        enableMultiRowSelection: () => {
            // HR - if contact modal, we want only 1 contact, but for products we want multi select enabled
            if (type === "contactModal") {
                return false
            } else {
                return true
            }
        },
        onRowSelectionChange: setRowSelection,
        enableHiding: true,
        meta: {
            editedRows,
            setEditedRows,
            revertData: (rowIndex, revert) => {
                if (revert) {
                    setData((old) =>
                        old.map((row, index) =>
                            index === rowIndex ? originalData[rowIndex] : row
                        )
                    );
                } else {
                    setOriginalData((old) =>
                        old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
                    );
                }
            },
            updateData: (rowIndex, columnId, value) => {
                setData((old) =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            };
                        }
                        return row;
                    })
                );
            },
            addRow: () => {
                // TODO - this will need to change for each table
                const newRow = {
                    studentId: Math.floor(Math.random() * 10000),
                    name: "",
                    dateOfBirth: "",
                    major: "",
                };
                const setFunc = (old) => [...old, newRow];
                setData(setFunc);
                setOriginalData(setFunc);
            },
            removeRow: (rowIndex) => {
                const setFilterFunc = (old) =>
                    old.filter((_row, index) => index !== rowIndex);
                setData(setFilterFunc);
                setOriginalData(setFilterFunc);
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        autoResetPageIndex: false
    })

    useEffect(() => {
        if (type === "contactModal" || type === "productModal") {
            setModalRows(table.getSelectedRowModel().flatRows)
        }
    }, [rowSelection])

    return (
        <div style={{ width: '100%', padding: '2rem' }}>
            <Container maxW="10xl" style={{ marginBottom: "1rem" }} centerContent>
                <HStack spacing={"5vw"}>
                    <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={value => setGlobalFilter(String(value))}
                        placeholder="Search all columns..."
                        width={"50"}
                        margin={"1rem"}
                    />
                </HStack>
            </Container>
            <TableContainer backgroundColor={"whiteAlpha.100"} border={"solid #4A5568 1px"} borderRadius={"1rem"} overflowX={'auto'} width={'100%'}>
                <Table variant={"striped"} style={{ border: "none" }} colorScheme="blue">
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <Th key={header.id} colSpan={header.colSpan} borderRight={"none"}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? "cursor-pointer select-none"
                                                                : "",
                                                            onClick: header.column.getToggleSortingHandler()
                                                        }}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: <FiChevronUp fontSize={"large"} />,
                                                            desc: <FiChevronDown fontSize={"large"} />
                                                        }[header.column.getIsSorted()] ?? null}
                                                    </div>
                                                    {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} />
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <Td key={cell.id} padding={'0.7rem'} w={'fit-content'}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
                <HStack justifyContent={"space-evenly"} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <HStack spacing={"2"}>
                        <Button
                            onClick={() => table.setPageIndex(0)}
                            isDisabled={!table.getCanPreviousPage()}
                        >
                            {'<<'}
                        </Button>
                        <Button
                            onClick={() => table.previousPage()}
                            isDisabled={!table.getCanPreviousPage()}
                        >
                            {'<'}
                        </Button>
                        <Button
                            onClick={() => table.nextPage()}
                            isDisabled={!table.getCanNextPage()}
                        >
                            {'>'}
                        </Button>
                        <Button
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            isDisabled={!table.getCanNextPage()}
                        >
                            {'>>'}
                        </Button>
                    </HStack>
                    <HStack spacing={"4"}>
                        <span>
                            <div>Page</div>
                            <strong>
                                {table.getState().pagination.pageIndex + 1} of{' '}
                                {table.getPageCount()}
                            </strong>
                        </span>
                        <span>
                            | Go to page:
                            <Input
                                type="number"
                                defaultValue={table.getState().pagination.pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    table.setPageIndex(page)
                                }}
                                width={"12"}
                            />
                        </span>
                    </HStack>
                    <Select
                        value={table.getState().pagination.pageSize}
                        width={32}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </Select>
                </HStack>
            </TableContainer>
        </div>
    )
}