import { Flex, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react"
import TanTable from "../components/TanStackTable/TanTable"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import AddCell from "../components/TanStackTable/AddCell"
import { getDocuments } from "../redux/Document/documentCalls"
import draftHistoryColumns from "../components/TanStackTable/Columns/History/draftHistoryColumns"

export default function DraftHistory() {
    const dispatch = useDispatch()
    const { drafts } = useSelector((state) => state.documents)
    const { account_id } = useSelector((state) => state.account)

    // HR - we make call to fetch document information which populates the table
    useEffect(() => {
        getDocuments(account_id, dispatch)
    }, [dispatch])

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={"2rem"}
        >
            <VStack w={'100%'}>
                {/* HR - AddCell displays a button w/ dropdown menu that links to either "/create/quote" or "create/purchaseorder" */}
                <AddCell type="draft_history" />
                {/* HR - history data is currently static, each row has cell to eventually view/edit document */}
                <TanTable columns={draftHistoryColumns} defaultData={drafts} type="history" />
            </VStack>
        </Flex>
    )
}