import {
    Button, Drawer, DrawerBody, DrawerContent,
    DrawerHeader, Stack, Select, RadioGroup,
    Radio, Text, Flex, Box, NumberInput,
    NumberInputField, NumberInputStepper, NumberIncrementStepper,
    NumberDecrementStepper, Container, VStack,
    useDisclosure
} from "@chakra-ui/react"
import { DrawerCloseButton } from "@chakra-ui/react"
import { textStyles } from "../../styles/PDFStyles";
import { useEffect, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import CloseDrawerConfirmation from "../CloseDrawerConfirmation";

export default function SortableForm({ addData, updateData, addInput, resetContent, confirmVisible, isOpen, onClose, textStyle, setTextStyle, inputMode,
    inputType, setInputType, spaceValue, setSpaceValue }) {

    const handleChange = (spaceValue) => setSpaceValue(spaceValue)
    const { isOpen: isPopupOpen, onOpen: onPopupOpen, onClose: onPopupClose } = useDisclosure()

    // HR - reset form to default to text input
    useEffect(() => {
        if (inputMode === "Add") {
            setInputType("text")
        }
    }, [isOpen])

    function closeContentDrawer() {
        if (addInput !== "") {
            onPopupOpen()
        } else {
            resetContent()
            onClose()
        }
    }

    return (
        <>
            <Drawer isOpen={isOpen} onClose={closeContentDrawer} placement="left" size={'sm'}>
                <Flex>
                    <DrawerContent>
                        <DrawerCloseButton onClick={closeContentDrawer} />
                        <Box flex={1}></Box>
                        <DrawerHeader>Add content</DrawerHeader>

                        <DrawerBody flex={10}>
                            <RadioGroup
                                onChange={(e) => {
                                    setInputType(e);
                                }}
                                style={{ margin: 15 }}
                                value={inputType}
                            >
                                <p>Select your content to input:</p>
                                <Stack spacing={5} direction="row" style={{ paddingTop: 10 }}>
                                    <Radio value="text">Text</Radio>
                                    <Radio value="space">Space</Radio>
                                    {/* <Radio value="image">Image</Radio> */}
                                </Stack>
                            </RadioGroup>
                            {inputType === "text" && (
                                <Container centerContent padding={'1rem'}>
                                    <TextareaAutosize
                                        style={{ width: '100%', border: '1px solid #7FB2E4', borderRadius: 5, padding: 5 }}
                                        minRows={2}
                                        maxRows={25}
                                        name="category"
                                        value={addInput}
                                        onChange={addData.button.onChange}
                                    />
                                    <VStack spacing={'1rem'} mt={"2rem"}>
                                        <Text>
                                            Choose a text style:
                                        </Text>
                                        <Flex>
                                            <Select
                                                onChange={(e) => setTextStyle(e.target.value)}
                                                value={textStyle}
                                            >
                                                {Object.keys(textStyles).map((key, index) => {
                                                    return (
                                                        <option key={index} value={key}>
                                                            {key}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                            <Button
                                                className={confirmVisible ? null : "disabled"}
                                                onClick={inputMode === "Add" ? addData.button.confirm : updateData.button.confirm}
                                                size="md"
                                                style={{ marginLeft: '1rem' }}
                                                colorScheme="messenger"
                                            >
                                                Submit
                                            </Button>
                                        </Flex>
                                    </VStack>
                                </Container>
                            )}
                            {/* {inputType === "image" && (
                                    <div className="marginTen divCenter">
                                        <input type="file" onChange={handleFileChange} accept="" />
                                        <Stack
                                            spacing={10}
                                            direction="row"
                                            style={{ paddingTop: 10 }}
                                        >
                                            <Button colorScheme="messenger" size="md" onClick={onImageUpload}>
                                                Submit
                                            </Button>
                                        </Stack>
                                    </div>
                                )} */}
                            {inputType === "space" && (
                                <Container centerContent padding={"1rem"}>
                                    <Text mb={'1rem'}> Choose the number of lines:</Text>
                                    <Flex>
                                        <NumberInput maxW='100px' mr='1rem' value={spaceValue} onChange={handleChange} min={1}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <Button
                                            onClick={inputMode === "Add" ? addData.button.confirm : updateData.button.confirm}
                                            size="md"
                                            colorScheme="messenger"
                                        >
                                            Submit
                                        </Button>
                                    </Flex>
                                </Container>
                            )}
                        </DrawerBody>
                    </DrawerContent>
                </Flex>
            </Drawer >
            <CloseDrawerConfirmation isOpen={isPopupOpen} onClose={onPopupClose} onDrawerClose={onClose} />
        </>
    )
}