import { Button, AlertDialog, AlertDialogOverlay, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader } from "@chakra-ui/react"
import { useRef } from "react"

export default function ArchiveConfirmation({isOpen, onOpen, onClose, removeRow, mode}) {
    const cancelRef = useRef()
    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {mode} Document
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? {mode === 'Archive' && 'You can undo this action later in the settings menu.'}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={removeRow} ml={3}>
                                {mode}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}