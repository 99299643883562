import { FcGoogle } from 'react-icons/fc'
import { Button, Center, Text } from '@chakra-ui/react'

export default function GoogleButton({onClickFunction, phrase}) {
    return (
        <Center p={2}>
            <Button maxW={'md'} variant={'outline'} leftIcon={<FcGoogle />} onClick={onClickFunction}>
                <Center>
                    <Text fontWeight={'normal'}>{phrase}</Text>
                </Center>
            </Button>
        </Center>
    )
}