import React from 'react';
import {
  Document,
  Font,
  Page,
  StyleSheet,
} from '@react-pdf/renderer';
import PDFDoc from "./PDFDoc"

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

// HR - basically a wrapper for our PDf document

export default function MyDocument(props) {

  return (
    <Document>
      <Page style={styles.body} wrap>
        <PDFDoc {...props} />
      </Page>
    </Document>
  )
}
