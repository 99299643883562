import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import storage from "redux-persist/lib/storage";

import documentSlice from "./Document/documentSlice";
import userSlice from "./User/userSlice"
import inviteUserSlice from "./InviteUser/inviteUserSlice";
import productSlice from "./Product/productSlice"
import contactSlice from "./Contact/contactSlice"
import authSlice from "./Auth/authSlice"
import createDocSlice from "./CreateDoc/createDocSlice";
import accountSlice from "./Account/accountSlice";
import paymentTermSlice from "./PaymentTerm/paymentTermSlice";
import resourceSlice from "./Resource/resourceSlice";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";


// HR - this is where we import all of our Redux slices, and combine the reducers into our single store
const rootReducer = combineReducers({
    documents: documentSlice,
    contacts: contactSlice,
    products: productSlice,
    users: userSlice,
    inviteUsers: inviteUserSlice,
    auth: authSlice,
    createDoc: createDocSlice,
    account: accountSlice,
    paymentTerms: paymentTermSlice,
    resources: resourceSlice
})

const appReducer = (state, action) => {
    if (action.type === 'RESET'){
        return rootReducer(undefined, action)
    }
    return rootReducer(state, action)
}


// HR - this is where we configure the settings for persisting our redux states
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['users', 'inviteUsers', 'createDoc', 'paymentTerms'],
    whitelist: ['account', 'auth', 'documents', 'contacts', 'products', 'resources'],
    stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, appReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    })
})
export const persistor = persistStore(store)
