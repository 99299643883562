import DebouncedInput from "./DebouncedInput"

export default function Filter({ column }) {

    const columnFilterValue = column.getFilterValue()
    // return 
    // typeof firstValue === "number" ? (
    //     <div>
    //         <DebouncedInput
    //             type="number"
    //             value={columnFilterValue ?? ""}
    //             onChange={value => column.setFilterValue(value)}
    //             label={column.columnDef.header}
    //             list={column.id + "list"}
    //             width={"4rem"}
    //         />
    //     </div>
    // ) : (
        return  <>
            <DebouncedInput
                type="text"
                value={columnFilterValue ?? ""}
                onChange={value => column.setFilterValue(value)}
                label={column.columnDef.header}
                list={column.id + "list"}
                width={"6rem"}
            />
        </>
    // )
}