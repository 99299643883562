import { createSlice } from "@reduxjs/toolkit";

export const inviteUserSlice = createSlice({
    name: "inviteUsers",
    initialState: {
        invite_users: [],
        addInviteUserProgress: false,
        getInviteUsersProgress: false,
        error: false,
    },
    reducers: {

        // add invite user
        addInviteUserStart: (state) => {
            state.addInviteUserProgress = true
        },
        addInviteUserSuccess: (state, action) => {
            state.invite_users.push(action.payload)
            state.addInviteUserProgress = false
        },
        addInviteUserFailure: (state) => {
            state.error = true
            state.addInviteUserProgress = false
        },

        // get invite users
        getInviteUsersStart: (state) => {
            state.getInviteUsersProgress = true
        },
        getInviteUsersSuccess: (state, action) => {
            state.invite_users = action.payload
            state.getInviteUsersProgress = false
        },
        getInviteUsersFailure: (state) => {
            state.error = true
            state.getInviteUsersProgress = false
        },

    },
})

export const {
    getInviteUsersStart,
    getInviteUsersSuccess,
    getInviteUsersFailure,
    addInviteUserStart,
    addInviteUserSuccess,
    addInviteUserFailure,
} = inviteUserSlice.actions

export default inviteUserSlice.reducer