import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Tooltip,
    ModalOverlay,
    Text,
    Container,
    HStack,
    Switch,
    Divider
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function EditRowDiscountPopup({ isOpen, onClose, row, rowDiscount, setRowDiscount, handleRowDiscountChange }) {
    const { global_discount_options } = useSelector((state) => state.createDoc)

    // HR - if row's discounts options does not equal the global discount options, set the defaults for this popup
    useEffect(() => {
        if (row.original.discount_options !== global_discount_options) {
            setRowDiscount(row.original.discount_options)
        }
    }, [])

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                style={{ zIndex: "--chakra-sizes-container-md" }}
                size={"md"}
                scrollBehavior='outside'
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <Container centerContent>
                        <ModalHeader>Edit Row Discount </ModalHeader>
                        <Divider />
                        <Text fontSize='lg' my={'1rem'} as={'u'}>{row.original.name} ({row.original.product_id})</Text>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <VStack spacing={'1.5rem'}>
                                <HStack justifyContent={'center'}>
                                    <FormLabel>{rowDiscount.use_cost ? 'Cost' : 'List Price'}</FormLabel>
                                    <Switch size={'lg'} variant={"totalStyle"} isChecked={rowDiscount.use_cost} onChange={(e) => setRowDiscount({ ...rowDiscount, use_cost: e.target.checked })} />
                                </HStack>
                                <HStack justifyContent={'center'}>
                                    <FormLabel>{rowDiscount.use_markup ? 'Markup' : 'Discount'}</FormLabel>
                                    <Switch size={'lg'} variant={"totalStyle"} isChecked={rowDiscount.use_markup} onChange={(e) => setRowDiscount({ ...rowDiscount, use_markup: e.target.checked })} />
                                </HStack>
                                <FormControl>
                                    <VStack>
                                        <FormLabel m={0} fontSize={'md'}>
                                            {rowDiscount.use_markup ? 'Markup (%)' : 'Discount (%)'}
                                        </FormLabel>
                                        <Input type={"number"} value={rowDiscount.percentage} onChange={(e) => setRowDiscount({ ...rowDiscount, percentage: e.target.value })} w={'5vw'} />
                                    </VStack>
                                </FormControl>
                                <Tooltip label="This will apply ONLY to this row and will not change the preset global configuration">
                                    <Button colorScheme={'purple'} onClick={handleRowDiscountChange}>Apply Changes</Button>
                                </Tooltip>
                            </VStack>
                        </ModalBody>
                    </Container>
                </ModalContent>
            </Modal>
        </>
    )
}