import TableCell from "../../TableCell"
import IndeterminateCheckbox from "../../IndeterminateCheckbox"

const productSelectPOColumns = [
    {
        header: "Select",
        id: "select",
        cell: ({ row }) => {
            return (
                <IndeterminateCheckbox checked={row.getIsSelected()} isDisabled={!row.getCanSelect()} indeterminate={row.getIsSomeSelected()} onChange={row.getToggleSelectedHandler()} />
            )
        }
    },
    {
        header: "Product ID",
        id: "product_id",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "product_id",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Name",
        id: "name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Description",
        id: "description",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "description",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Category",
        id: "category",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "category",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Cost",
        id: "cost",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "cost",
        type: 'number',
        step: '0.01',
        filterFn: "fuzzy"
    },
]

export default productSelectPOColumns