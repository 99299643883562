import { Flex, VStack, useColorModeValue, Button, Divider, Heading } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { setEditMode } from "../../redux/CreateDoc/createDocSlice";

export default function EditOptions() {
    const { doc_type } = useSelector((state) => state.createDoc)
    const { active } = useSelector((state) => state.createDoc)
    const { is_complete } = useSelector((state) => state.createDoc)
    const { account_initial } = useSelector((state) => state.account)
    const { user_initials } = useSelector((state) => state.auth)
    const { user_role } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    var urlType = '';
    // HR - this if branch is used to generate the route that will be used to edit or view the document
    if (doc_type === 'PO') {
        urlType = 'purchaseorder'
    } else if (doc_type === 'Quote') {
        urlType = 'quote'
    } else if (doc_type === 'Invoice') {
        urlType = 'invoice'
    }

    function handleEditNavigate(mode) {
        if (mode === 'invoice') {
            urlType = 'invoice'
        }
        dispatch(setEditMode({ mode: mode, account_initial: account_initial, user_initials: user_initials }))
        if(mode === "view"){
            navigate('/view/' + urlType)
        }else{
            navigate('/edit/' + urlType)

        }
    }

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={"2rem"}
        >

            <VStack spacing={'2rem'} pt={'2rem'}>
                <Heading as='h4' size='md'>
                    View Document
                </Heading>
                <Button onClick={() => handleEditNavigate('view')} colorScheme={'blue'}>View Existing</Button>
                {/* HR - if document is NOT inactive, allow for editing */}
                {active !== 'inactive' &&
                    <>
                        <Divider py={'1rem'} />
                        <Heading as='h4' size='md'>
                            Edit Document
                        </Heading>
                        {/* HR - if document is invoice, only admin & owner users can edit existing */}
                        {(doc_type !== "Invoice" || (user_role === "admin" || user_role === "owner")) && <Button onClick={() => handleEditNavigate('existing')} colorScheme={'green'}>Edit Existing</Button>}
                        <Button onClick={() => handleEditNavigate('new')} colorScheme={'orange'}>Edit As New</Button>
                        {(doc_type === 'Quote' && is_complete) && <Button onClick={() => handleEditNavigate('invoice')} colorScheme={'purple'}>Edit As Invoice</Button>}
                    </>
                }
            </VStack>
        </Flex>
    )
}