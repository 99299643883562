import TableCell from "../../TableCell"
import OpenCell from "../../OpenCell"

// HR - historyColumns shows document data and contains OpenCell at the tail of the row to open document for editing (not currently functional)

const poHistoryColumns = [
    {
        header: "PO #",
        id: "doc_number",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "doc_number",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Version",
        id: "version",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "version",
        type: "number",
        filterFn: "fuzzy"
    },
    {
        header: "Created By",
        id: "created_by",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "created_by",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Date Created",
        id: "date_created",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "date_created",
        type: "date",
        filterFn: "fuzzy"
    },
    {
        header: "Company Name",
        id: "contact.company_name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "contact.company_name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Updated By",
        id: "updated_by",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "updated_by",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Last Updated",
        id: "date_updated",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "date_updated",
        type: "date",
        filterFn: "fuzzy"
    },
    {
        header: "Grand Total",
        id: "total.grand_total",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "total.grand_total",
        type: 'number',
        step: '0.01',
        filterFn: "fuzzy"
    },
    {
        header: "Actions",
        id: "actions",
        cell: ({ row, table }) => {
            return (
                <OpenCell row={row} table={table} />
            )
        },
    }
]

export default poHistoryColumns