import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys)

const totalStyle = definePartsStyle({
    track: {
        bg: `rgb(128, 90, 213)`,
        _checked: {
            bg: `rgb(49, 151, 149)`,
        },
        _dark: {
            bg: `rgb(214, 188, 250)`,
            _checked: {
                bg: `rgb(129, 230, 217)`,
            }
        },
    }
})

export const switchTheme = defineMultiStyleConfig({ variants: { totalStyle } })