import { useDisclosure, HStack, Tooltip, IconButton } from "@chakra-ui/react"
import { useDispatch, useSelector } from 'react-redux';
import { TbPencilDiscount } from "react-icons/tb";
import EditRowDiscountPopup from "../EditRowDiscountPopup";
import { updateItemize } from "../../redux/CreateDoc/createDocSlice";
import { useState } from "react";

export default function EditDiscountCell({ row, table, tableType }) {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [rowDiscount, setRowDiscount] = useState({
        use_cost: false,
        use_markup: false,
        percentage: 0,
    })

    function handleRowDiscountChange() {
        dispatch(updateItemize({
            ...row.original,
            discount_options: rowDiscount
        }))
    }

    return (

        <>
            <Tooltip label='Set Row Discount' fontSize='sm'>
                <IconButton onClick={onOpen} variant={"outline"} borderColor={"rgb(128, 90, 213)"} ml={'2rem'} icon={<TbPencilDiscount color="rgb(128, 90, 213)" />} name="edit" />
            </Tooltip>
            <EditRowDiscountPopup isOpen={isOpen} onClose={onClose} row={row}  rowDiscount={rowDiscount} setRowDiscount={setRowDiscount} handleRowDiscountChange={handleRowDiscountChange} />
        </>
    )
}
