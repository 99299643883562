import axios from 'axios'
import {toast} from 'react-toastify'
import * as actions from "./documentSlice"
import getApiUri from '../../helper/getApiUri'
import { updateDocumentNumber } from '../Account/accountSlice'

const apiUrl =  getApiUri() + "/documents"

export const createDocument = async (payload, dispatch) => {
    dispatch(actions.createDocumentStart())
    try {
        const {data} = await axios.post(apiUrl, payload)
        dispatch(actions.createDocumentSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        dispatch(actions.createDocumentFailure())
        toast.error(error.response.data.message)
        return false
    }
}

export const getDocuments = async (payload, dispatch) => {
    dispatch(actions.getDocumentsStart())
    try {
        const {data} = await axios.get(apiUrl + `/${payload}`)
        dispatch(actions.getDocumentsSuccess(data.data))
        return true
    } catch (error) {
        dispatch(actions.getDocumentsFailure())
        return false
    }
}

export const updateDocument = async (payload, dispatch) => {
    dispatch(actions.updateDocumentStart())
    try {
        const url = apiUrl + '/' + payload.doc_number
        const {data} = await axios.put(url, payload)
        dispatch(actions.updateDocumentSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.updateDocumentFailure())
        toast.error(error.response.data.message)
        return false
    }
}

export const deleteDocument = async (id, dispatch) => {
    dispatch(actions.deleteDocumentStart())
    try {
        const {data} = await axios.delete(apiUrl + "/" + id)
        dispatch(actions.deleteDocumentSuccess(id))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.deleteDocumentFailure())
        return false
    }
}

export const getLastDocumentId = async (payload, dispatch) => {
    try {
        const {data} = await axios.get(apiUrl + `/latest/${payload}`)
        dispatch(updateDocumentNumber({mode: 'fetch', document_number: data.data}))
        return true
    } catch (error) {
        return false
    }
}

// export const updateAllDocuments = async (payload, dispatch) => {
//     try {
//         const url = apiUrl + '/updateAll/test'
//         const {data} = await axios.put(url, payload)
//         toast.success(data.message)
//         return true
//     } catch (error) {
//         console.log(error)
//         toast.error(error.response.data.message)
//         return false
//     }
// }