import { Stepper, Step, StepIndicator, StepStatus, StepNumber, StepIcon, Box, StepSeparator, StepTitle, StepDescription } from '@chakra-ui/react'

export default function MyStepper({ activeStep, steps }) {

    return (
        <>
        <Stepper index={activeStep}>
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>
                    <Box flexShrink='0'>
                        <StepTitle>{step.title}</StepTitle>
                        {step.description && <StepDescription>{step.description}</StepDescription>}
                    </Box>
                    <StepSeparator style={{ width: "3vw" }} />
                </Step>
            ))}
        </Stepper>
        </>
    )
}