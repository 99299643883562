import { Flex, useColorModeValue, Container, Card, CardBody, Text, FormControl, FormLabel, Input, Textarea, Button, VStack, FormHelperText, HStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GiBugNet } from "react-icons/gi";
import { reportBug } from "../redux/Auth/authCalls";

export default function ReportBug() {
    const { email } = useSelector((state) => state.auth)
    const [bugSteps, setBugSteps] = useState("")
    const [uploadedImages, setUploadedImages] = useState([])
    const dispatch = useDispatch()

    async function handleSubmitBug(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("bugEmail", email)
        formData.append("bugSteps", bugSteps)
        if (uploadedImages.length !== 0) {
            for (const single_image of uploadedImages) {
                formData.append('bugImages', single_image)
            }
        }
        reportBug(formData, dispatch)
        setUploadedImages([])
        setBugSteps("")
        document.getElementById("fileInput").value = null
    }

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={"2rem"}
        >
            <Container centerContent mt={'5vh'}>
                <Card width={'80%'}>
                    <CardBody>
                        <Container centerContent mt={'1.5rem'} mb={'1rem'}>
                            <Text fontSize="3xl" mb={'2rem'} fontFamily="heading" fontWeight="light">Bug Report Form</Text>
                            <form onSubmit={handleSubmitBug}>
                                <VStack spacing={'2rem'}>
                                    <FormControl>
                                        <FormLabel>Your Email</FormLabel>
                                        <Input placeholder='Contact ID' name="email" value={email} disabled />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Steps to reproduce the issue</FormLabel>
                                        <Textarea name="steps" value={bugSteps} onChange={(e) => setBugSteps(e.target.value)} />
                                        <FormHelperText>Please be as detailed as possible!</FormHelperText>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Screenshot(s) of the issue</FormLabel>
                                        <Input placeholder='Choose File' type="file" accept={'image/*'} variant={"flushed"} multiple onChange={(e) => setUploadedImages(e.target.files)} id="fileInput" />
                                    </FormControl>
                                    <Button colorScheme={"purple"} type="submit">Report Bug</Button>
                                </VStack>
                            </form>
                        </Container>
                    </CardBody>
                </Card>
            </Container>
        </Flex>
    )
}