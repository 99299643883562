import { Container, Text, Button, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


export default function CompletePDF({ type, mode }) {
    const navigate = useNavigate()
    return (
        <Container centerContent>
            <VStack spacing={'1rem'}>
                <Text>Your {type} has successfully been created!</Text>
                <Button colorScheme={"blue"} onClick={()=> navigate('/')}>Click to Return Home</Button>
            </VStack>
        </Container>
    )
}