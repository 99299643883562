import { Flex, VStack, useColorModeValue, useDisclosure } from "@chakra-ui/react"
import TanTable from "../components/TanStackTable/TanTable"
import productColumns from "../components/TanStackTable/Columns/Products/productColumns"
import AddCell from "../components/TanStackTable/AddCell"
import AddProductPopup from "../components/AddProductPopup"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getProducts } from "../redux/Product/productCalls"

export default function Products() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const { products } = useSelector((state) => state.products)
    const { account_id } = useSelector((state) => state.account)

    // HR - we make call to fetch product information which populates the table
    useEffect(() => {
        getProducts(account_id, dispatch)
    }, [dispatch])

    var columns = productColumns


    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={"2rem"}
        >
            <VStack w={'100%'}>
                {/* HR - AddCell is the "Add New " button */}
                <AddCell type="Product" openModal={onOpen} />
                {/* HR - products table is read, edit, and delete */}
                {products && <TanTable columns={columns} defaultData={products} type="products" />}
            </VStack>
            {/* HR - this form modal opens when the user clicks "Add New" */}
            <AddProductPopup isOpen={isOpen} onClose={onClose} />

        </Flex>
    )
}