import { createSlice } from "@reduxjs/toolkit";

export const contactSlice = createSlice({
    name: "contacts",
    initialState: {
        contacts: [],
        createContactProgress: false,
        getContactsProgress: false,
        updateContactProgress: false,
        deleteContactProgress: false,
        error: false,
    },
    reducers: {
        // create contact
        createContactStart: (state) => {
            state.createContactProgress = true
        },
        createContactSuccess: (state, action) => {
            state.contacts.push(action.payload)
            state.createContactProgress = false
        },
        createContactFailure: (state) => {
            state.error = true
            state.createContactProgress = false
        },

        // get contact
        getContactsStart: (state) => {
            state.getContactsProgress = true
        },
        getContactsSuccess: (state, action) => {
            state.contacts = action.payload
            state.getContactsProgress = false
        },
        getContactsFailure: (state) => {
            state.error = true
            state.getContactsProgress = false
        },

        // update contact
        updateContactStart: (state) => {
            state.updateContactProgress = true
        },
        updateContactSuccess: (state, action) => {
            state.updateContactProgress = false
        },
        updateContactFailure: (state) => {
            state.error = true
            state.updateContactProgress = false
        },

        // get contact
        deleteContactStart: (state) => {
            state.deleteContactProgress = true
        },
        deleteContactSuccess: (state, action) => {
            state.contacts = state.contacts.filter(
                (contact) => contact._id !== action.payload
            )
            state.deleteContactProgress = false
        },
        deleteContactFailure: (state) => {
            state.error = true
            state.deleteContactProgress = false
        }

    },
})

export const {
    createContactStart,
    createContactSuccess,
    createContactFailure,
    getContactsStart,
    getContactsSuccess,
    getContactsFailure,
    updateContactStart,
    updateContactSuccess,
    updateContactFailure,
    deleteContactStart,
    deleteContactSuccess,
    deleteContactFailure,
} = contactSlice.actions

export default contactSlice.reducer