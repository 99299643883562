const quoteDiscountHeader = {
    id: "7gzxlgpk6491bz6zwhu6943z",
    items: [
        {
            sr: 0,
            product_id: "Id",
            name: "Name",
            description: "Description",
            quantity: "Qt.",
            calculated_price: "Price",
            total: "Total",
        },
    ],
};

export default quoteDiscountHeader;
