import { Button, AlertDialog, AlertDialogOverlay, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader } from "@chakra-ui/react"
import { useRef } from "react"

export default function DeleteConfirmation({isOpen, onOpen, onClose, type, removeRow}) {
    const cancelRef = useRef()
    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {type === "createPDF" ? 'Remove product' : 'Delete ' + type }
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action later.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={removeRow} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}