import TableCell from "../../TableCell"
import EditCell from "../../EditCell"

const editProductQuoteColumns = [
    {
        header: "Product ID",
        id: "product_id",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"createPDF"} />
            )
        },
        accessorKey: "product_id",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Name",
        id: "name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"createPDF"} />
            )
        },
        accessorKey: "name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Description",
        id: "description",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"createPDF"} />
            )
        },
        accessorKey: "description",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Qty.",
        id: "quantity",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"createPDF"} />
            )
        },
        accessorKey: "quantity",
        type: 'number',
        filterFn: "fuzzy"
    },
    {
        header: "List Price",
        id: "list_price",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"createPDF"} />
            )
        },
        accessorKey: "list_price",
        type: 'number',
        step: '0.01',
        filterFn: "fuzzy"
    },
    {
        header: "Total",
        id: "total",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} disableCell={true} tableType={"createPDF"} />
            )
        },
        accessorKey: "total",
        type: 'number',
        step: '0.01',
        filterFn: "fuzzy"
    },
    {
        header: "Actions",
        id: "actions",
        cell: ({ row, table }) => {
            return (
                <EditCell row={row} table={table} tableType={'createPDF'} />
            )
        },
    }
]

export default editProductQuoteColumns