import { useDisclosure, FormLabel, Input, Stack, HStack, IconButton, FormControl, VStack, Switch, Text, Container, Textarea, Button, Tooltip, RadioGroup, Radio, Center } from '@chakra-ui/react'
import { FiUserPlus, FiUsers } from 'react-icons/fi'
import ContactModal from '../ContactModal';
import { useEffect, useState } from 'react';
import { initializeSuccess, initializeSave, manualContentChange, manualContentSave } from '../../redux/CreateDoc/createDocSlice';
import { useDispatch, useSelector } from 'react-redux';
import handleInputChange from '../../helper/inputChange';
import { toast } from 'react-toastify';
import AddContactPopup from "../AddContactPopup"
import { getLastDocumentId } from '../../redux/Document/documentCalls';

export default function InitializePDF({ type, mode }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure()

    const dispatch = useDispatch()
    const [contactRows, setContactRows] = useState({})
    const [contactIndex, setContactIndex] = useState(null)
    const { contact } = useSelector((state) => state.createDoc)
    const { account_id } = useSelector((state) => state.account)
    const { doc_number } = useSelector((state) => state.createDoc)
    const { date } = useSelector((state) => state.createDoc)
    const { shipping_content } = useSelector((state) => state.createDoc)
    const [generationMode, setGenerationMode] = useState('auto')
    const { document_number_status } = useSelector((state) => state.account)
    const { documents } = useSelector((state) => state.documents)
    const docNumbers = documents.map((document) => document.doc_number)
    const [shippingInfo, setShippingInfo] = useState({
        shipping_is_same: false,
        ship_to_company: "",
        ship_to_address_1: "",
        ship_to_address_2: "",
        ship_to_city: "",
        ship_to_state: "",
        ship_to_country: "",
        ship_to_zip: "",
        attention: "",
        mark_package: ""
    })
    const [manualDocInfo, setManualDocInfo] = useState({
        docNumber: "",
        date: "",
    })

    function handleDoneClick() {
        // HR - check to make sure user has selected contact before sending it to redux state, 
        // will error if state is empty in next stage
        if (contactRows.length > 0) {
            dispatch(initializeSuccess({ contactRows: contactRows[0], contactIndex: contactIndex }))
        }
        onClose()
    }

    function handleSubmitForm(e) {
        e.preventDefault()
        if (generationMode === "auto") {
            dispatch(initializeSave({ "mode": "auto", "shipping": shippingInfo, "docNum": doc_number }))
            toast.success("Successfully saved!")
        } else if (docNumbers.includes(manualDocInfo.docNumber)) { // HR - check if manually entered doc_number is already attached to a document
            toast.error('That document number has already been used! Resetting to an available option...')
            setManualDocInfo((newDoc) => ({ ...newDoc, docNumber: doc_number }))
        } else {
            dispatch(initializeSave({ "mode": "manual", "shipping": shippingInfo, "manInfo": manualDocInfo }))
            toast.success("Successfully saved!")
        }
    }

    // HR - check which state to update 
    function inputChange(e) {
        if (e.target.name === "docNumber" || e.target.name === "date") {
            handleInputChange(e.target, setManualDocInfo)
        } else {
            handleInputChange(e.target, setShippingInfo)
        }
    }

    useEffect(() => {
        // HR - if existing state for shipping_content, set state to saved values
        if (shipping_content && Object.keys(shipping_content).length > 0) {
            setShippingInfo({
                shipping_is_same: shipping_content.shipping_is_same,
                ship_to_company: shipping_content.ship_to_company,
                ship_to_address_1: shipping_content.ship_to_address_1,
                ship_to_address_2: shipping_content.ship_to_address_2,
                ship_to_city: shipping_content.ship_to_city,
                ship_to_state: shipping_content.ship_to_state,
                ship_to_country: shipping_content.ship_to_country,
                ship_to_zip: shipping_content.ship_to_zip,
                attention: shipping_content.attention,
                mark_package: shipping_content.mark_package
            })
        }
    }, [])

    useEffect(() => {
        // HR - if the most recent doc_number has been fetched, automatically set doc_number to that value
        setManualDocInfo((newDoc) => ({ ...newDoc, docNumber: doc_number, date: date }))
    }, [doc_number])

    useEffect(() => {
        // HR - if document number was not recently set in settings, use number fetched from last document to increment
        if (document_number_status !== "updated") {
            const getLastIdFn = async () => { getLastDocumentId(account_id, dispatch) }
            getLastIdFn()
        }
        // HR - if user switches back to auto generation, reset document_number
        if (generationMode === "auto") {
            setManualDocInfo((newDoc) => ({ ...newDoc, docNumber: doc_number }))
        }
    }, [generationMode])


    var contactType = null
    if (type === "PO") {
        contactType = "Vendor"
    } else {
        contactType = "Customer"
    }

    useEffect(() => {
        // HR - if existing state for shipping_info, autofill input fields and switch value
        if (Object.keys(shipping_content).length > 0) {
            setShippingInfo({
                shipping_is_same: shipping_content.shipping_is_same,
                ship_to_company: shipping_content.ship_to_company,
                ship_to_address_1: shipping_content.ship_to_address_1,
                ship_to_address_2: shipping_content.ship_to_address_2,
                ship_to_city: shipping_content.ship_to_city,
                ship_to_state: shipping_content.ship_to_country,
                ship_to_country: shipping_content.ship_to_country,
                ship_to_zip: shipping_content.ship_to_zip,
                mark_package: shipping_content.mark_package,
                attention: shipping_content.attention
            })
        }
    }, [])

    function handleToggleSwitch(e) {
        if (e.target.checked) {
            setShippingInfo({
                shipping_is_same: e.target.checked,
                ship_to_company: contact.company_name,
                ship_to_address_1: contact.address_1,
                ship_to_address_2: contact.address_2,
                ship_to_city: contact.city,
                ship_to_state: contact.state,
                ship_to_country: contact.country,
                ship_to_zip: contact.zip,
                mark_package: "",
                attention: ""
            })
        } else {
            setShippingInfo({
                shipping_is_same: false,
                ship_to_company: "",
                ship_to_address_1: "",
                ship_to_address_2: "",
                ship_to_city: "",
                ship_to_state: "",
                ship_to_country: "",
                ship_to_zip: "",
                attention: "",
                mark_package: ""
            })
        }
    }

    return (
        <>
            <Stack spacing={5} mt={5} w={"17.5vw"}>
                <FormControl>
                    <Container centerContent mb={'1rem'} border={'2px solid rgb(226, 232, 240)'} p="1rem" borderRadius={'10px'}>
                        <FormLabel>{type} Number Generation</FormLabel>
                        <RadioGroup value={generationMode} onChange={(val) => setGenerationMode(val)}>
                            <Stack spacing={5} direction='row'>
                                <Radio colorScheme='green' value='auto'>
                                    Auto
                                </Radio>
                                <Radio colorScheme='red' value='manual'>
                                    Manual
                                </Radio>
                            </Stack>
                        </RadioGroup>
                    </Container>
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>{type} Number</FormLabel>
                    <Input name="docNumber" variant={generationMode === "auto" ? "filled" : "outline"} value={manualDocInfo.docNumber} onChange={inputChange} disabled={generationMode === "auto" ? true : false} style={{ opacity: '1', cursor: 'default' }} />
                </FormControl>

                {/* <FormControl>
                    <FormLabel>{type} Number</FormLabel>
                    <Input variant="filled" value={doc_number} type="text" disabled style={{ opacity: '1', cursor: 'default' }} />
                </FormControl> */}


                <FormControl isRequired>
                    <FormLabel>Date</FormLabel>
                    <Input name="date" variant={generationMode === "auto" ? "filled" : "outline"} type="date" value={manualDocInfo.date} onChange={inputChange} disabled={generationMode === "auto" ? true : false} style={{ opacity: '1', cursor: 'default' }} />
                </FormControl>
                {/*                 
                <FormControl>
                    <FormLabel>Date</FormLabel>
                    <Input variant="filled" type="date" value={date} disabled style={{ opacity: '1', cursor: 'default' }} />
                </FormControl> */}

                <Container centerContent>
                    <Text mt={3} fontSize={'medium'}>{contactType} Information</Text>
                </Container>

                <FormControl>
                    <HStack>
                        <FormLabel>{contactType} Contact</FormLabel>
                        {mode !== 'view' && <>
                            <Tooltip label='Select Contact' fontSize='sm'><IconButton variant={"ghost"} onClick={onOpen} icon={<FiUsers style={{ fontSize: "1.5rem", color: "#4299e1" }} />} /></Tooltip>
                            <Tooltip label='Create New Contact' fontSize='sm'><IconButton variant={"ghost"} onClick={onContactOpen} icon={<FiUserPlus style={{ fontSize: "1.5rem", color: "#4299e1" }} />} /></Tooltip>
                        </>}
                    </HStack>
                </FormControl>

                <FormControl>
                    <Input variant="filled" placeholder="Company Name" id="company_name" type="text" value={contact.company_name} disabled style={{ opacity: '1', cursor: 'default' }} />
                </FormControl>

                <FormControl>
                    <Input variant="filled" placeholder="Contact Name" id="name" type="text" value={contact.name} disabled style={{ opacity: '1', cursor: 'default' }} />
                </FormControl>

                <FormControl>
                    <Input variant="filled" placeholder="Email" type="text" value={contact.email} disabled style={{ opacity: '1', cursor: 'default' }} />
                </FormControl>

                <FormControl>
                    <Input variant="filled" placeholder="Phone" type="text" value={contact.phone} disabled style={{ opacity: '1', cursor: 'default' }} />
                </FormControl>


                <FormControl>
                    <FormLabel mt={2}>{contactType} Billing Address</FormLabel>
                    <VStack spacing={5}>
                        <Input
                            name="address_1" variant="filled" placeholder="Street Address" type="text" value={contact.address_1} disabled style={{ opacity: '1', cursor: 'default' }}
                        />
                        <Input
                            name="address_2" variant="filled" placeholder="Apt Number" type="text" value={contact.address_2} disabled style={{ opacity: '1', cursor: 'default' }}
                        />
                        <Input
                            name="city" variant="filled" placeholder="City" type="text" value={contact.city} disabled style={{ opacity: '1', cursor: 'default' }}
                        />
                        <Input
                            name="state" variant="filled" placeholder="State" type="text" value={contact.state} disabled style={{ opacity: '1', cursor: 'default' }}
                        />
                        <Input
                            name="country" variant="filled" placeholder="Country" type="text" value={contact.country} disabled style={{ opacity: '1', cursor: 'default' }}
                        />
                        <Input
                            name="zip" variant="filled" placeholder="Zipcode" type="text" value={contact.zip} disabled style={{ opacity: '1', cursor: 'default' }}
                        />
                    </VStack>
                </FormControl>

                <Container centerContent>
                    <Text mt={3} fontSize={'medium'}>Shipping Information</Text>
                </Container>

                <FormControl >
                    <HStack justifyContent={'center'} mt={3}>
                        <FormLabel>{shippingInfo.shipping_is_same ? 'Same as Billing' : 'Input Manually'}</FormLabel>
                        <Switch size={'lg'} colorScheme={'teal'} isChecked={shippingInfo.shipping_is_same} onChange={handleToggleSwitch} />
                    </HStack>
                </FormControl>

                <form onSubmit={handleSubmitForm}>
                    <FormControl>
                        <FormLabel mt={2}>Shipping to Company</FormLabel>
                        <Input variant={shippingInfo.shipping_is_same ? "filled" : "outline"} placeholder="Company Name" type="text" value={shippingInfo.ship_to_company}
                            disabled={shippingInfo.shipping_is_same} style={shippingInfo.shipping_is_same ? { opacity: '1', cursor: 'default' } : null} onChange={inputChange} name="ship_to_company" />
                    </FormControl>

                    <FormControl>
                        <FormLabel mt={2}>Shipping Address</FormLabel>
                        <VStack spacing={5}>
                            <Input
                                name="ship_to_address_1" variant={shippingInfo.shipping_is_same ? "filled" : "outline"} placeholder="Street Address" type="text" value={shippingInfo.ship_to_address_1}
                                disabled={shippingInfo.shipping_is_same} style={shippingInfo.shipping_is_same ? { opacity: '1', cursor: 'default' } : null} onChange={inputChange}
                            />
                            <Input
                                name="ship_to_address_s" variant={shippingInfo.shipping_is_same ? "filled" : "outline"} placeholder="Apt Number" type="text" value={shippingInfo.ship_to_address_2}
                                disabled={shippingInfo.shipping_is_same} style={shippingInfo.shipping_is_same ? { opacity: '1', cursor: 'default' } : null} onChange={inputChange}
                            />
                            <Input
                                name="ship_to_city" variant={shippingInfo.shipping_is_same ? "filled" : "outline"} placeholder="City" type="text" value={shippingInfo.ship_to_city}
                                disabled={shippingInfo.shipping_is_same} style={shippingInfo.shipping_is_same ? { opacity: '1', cursor: 'default' } : null} onChange={inputChange}
                            />
                            <Input
                                name="ship_to_state" variant={shippingInfo.shipping_is_same ? "filled" : "outline"} placeholder="State" type="text" value={shippingInfo.ship_to_state}
                                disabled={shippingInfo.shipping_is_same} style={shippingInfo.shipping_is_same ? { opacity: '1', cursor: 'default' } : null} onChange={inputChange}
                            />
                            <Input
                                name="ship_to_country" variant={shippingInfo.shipping_is_same ? "filled" : "outline"} placeholder="Country" type="text" value={shippingInfo.ship_to_country}
                                disabled={shippingInfo.shipping_is_same} style={shippingInfo.shipping_is_same ? { opacity: '1', cursor: 'default' } : null} onChange={inputChange}
                            />
                            <Input
                                name="ship_to_zip" variant={shippingInfo.shipping_is_same ? "filled" : "outline"} placeholder="Zipcode" type="text" value={shippingInfo.ship_to_zip}
                                disabled={shippingInfo.shipping_is_same} style={shippingInfo.shipping_is_same ? { opacity: '1', cursor: 'default' } : null} onChange={inputChange}
                            />
                        </VStack>
                    </FormControl>
                    {type === "PO" && <>
                        <FormControl>
                            <FormLabel mt={2}>Attention</FormLabel>
                            <Textarea variant={"outline"} placeholder='Important shipping notes here...' type="text" value={shippingInfo.email} onChange={inputChange} name="attention" />
                        </FormControl>

                        <FormControl>
                            <FormLabel mt={2}>Mark Package</FormLabel>
                            <Input variant={"outline"} placeholder='Limited Quantity, Hazardous Substance, etc.' type="text" value={shippingInfo.email} onChange={inputChange} name="mark_package" />
                        </FormControl>
                    </>}
                    <Container centerContent>
                        <Button mt={'2rem'} colorScheme={"green"} type="submit">Save</Button>
                    </Container>
                </form>
            </Stack>
            {mode !== 'view' && <>
                <ContactModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} setContactRows={setContactRows} handleClick={handleDoneClick} setContactIndex={setContactIndex} />
                <AddContactPopup isOpen={isContactOpen} onClose={onContactClose} />
            </>}
        </>
    )
}