import {
    Flex,
    Stack,
    Heading,
    useColorModeValue,
    useSteps,
    VStack,
} from "@chakra-ui/react"
import CompanyForm from "../components/SignUpCompany/CompanyForm"
// import SubscriptionForm from "../components/SignUpCompany/SubscriptionForm"
import SetupForm from "../components/SignUpCompany/SetupForm"
import MyStepper from '../components/MyStepper'
import { useSelector } from "react-redux"

export default function SignupCompany() {
    //  HR - this is the page where you sign up an account (contains 2 steps)
    const steps = [
        { title: 'Company Info' },
        // { title: 'Billing Info' },
        { title: 'Account Setup' },
    ]
    const { error } = useSelector((state) => state.account)

    // HR - state variable used to track current step
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    function incrementStep() {
        if (activeStep !== 4 && !error) {
            setActiveStep(activeStep + 1)
        }
    }

    return (
        <Flex
            minH={"145vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} mt={18} w={"2xl"} py={12} px={10}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"} textAlign={"center"}>
                        Sign up
                    </Heading>
                    <Heading fontSize={"2xl"} textAlign={"center"}>
                        (Company)
                    </Heading>
                </Stack>
                <VStack spacing={10}>
                    <MyStepper activeStep={activeStep} steps={steps} />
                    {activeStep === 0 && <CompanyForm incrementStep={incrementStep} />}
                    {/* {activeStep === 1 && <SubscriptionForm incrementStep={incrementStep} />} */}
                    {activeStep === 1 && <SetupForm incrementStep={incrementStep} />}
                </VStack>
            </Stack>
        </Flex>
    )
}
