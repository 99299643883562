import {
    Flex, useColorModeValue, TabList, Tab, Tabs, TabPanels, TabPanel, Card, CardBody, Button,
    useDisclosure, Stack, FormControl, FormLabel, Input, Text
} from "@chakra-ui/react"
import InviteUsersModal from "../components/SignUpCompany/InviteUsersModal"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { getUsers } from "../redux/User/userCalls"
import TanTable from "../components/TanStackTable/TanTable"
import userColumns from "../components/TanStackTable/Columns/Admin/userColumns"
import { StyleSheet } from "@react-pdf/renderer";
import archivedDocumentColumns from "../components/TanStackTable/Columns/Admin/archivedDocumentColumns"
import { getDocuments } from "../redux/Document/documentCalls"
import ManageCompanyInfo from "../components/Settings/ManageCompanyInfo"
import { getPaymentTerms } from "../redux/PaymentTerm/paymentTermCalls";
import ManagePaymentTerms from "../components/Settings/ManagePaymentTerms";
import { updateUser } from "../redux/User/userCalls"
import { getAccountById } from "../redux/Account/accountCalls"
import ManageTermsAndConditions from "../components/Settings/ManageTermsAndConditions"

export default function Settings() {
    const dispatch = useDispatch();
    const { account_id } = useSelector((state) => state.account)
    const { users } = useSelector((state) => state.users)
    const { archived_documents } = useSelector((state) => state.documents)
    const { user_role } = useSelector((state) => state.auth)
    const { user_id } = useSelector((state) => state.auth)
    const { user_name } = useSelector((state) => state.auth)
    const [displayName, setDisplayName] = useState(user_name)
    const [userIsDisabled, setUserIsDisabled] = useState(true)

    // HR - we given nicknames to the various useDisclosure methods since we use 3 different modals
    const { isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose } = useDisclosure()

    useEffect(() => {
        // HR - we don't waste resources to get user data if user should not have access to that information
        if (user_role === "owner" || user_role === "admin") {
            getAccountById(account_id, dispatch)
            getUsers(account_id, dispatch)
            getDocuments(account_id, dispatch)
            getPaymentTerms(account_id, dispatch)
        }
    }, [])

    // HR - these styles are used to make disabled inputs look more natural and enabled inputs clearly differentiated
    const styles = StyleSheet.create({
        disabledInput: {
            opacity: '1',
            cursor: 'default'
        },
        normalInput: {
            borderColor: '#3182ce6b'
        },
    });

    function handleSaveUser() {
        updateUser({ update: "name", display_name: displayName, id: user_id }, dispatch)
    }


    return (
        <>
            <Flex
                minH={"100vh"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
            >
                <Card style={{ margin: "4rem", height: "fit-content", width: "80vw" }}>
                    <CardBody>
                        <Tabs variant='enclosed' size={"lg"} align="center" overflow={'scroll'}>
                            <TabList style={{ marginTop: "2rem", overflowX: 'scroll' }}>
                                <Tab>User</Tab>
                                {/* HR - dynamically render this tab depending on user's role */}
                                {(user_role === "owner" || user_role === "admin") && <Tab>Company</Tab>}
                                {(user_role === "owner" || user_role === "admin") && <Tab>Payment Terms</Tab>}
                                {(user_role === "owner" || user_role === "admin") && <Tab>Terms & Conditions</Tab>}
                                {(user_role === "owner" || user_role === "admin") && <Tab>Manage Users</Tab>}
                                {(user_role === "owner" || user_role === "admin") && <Tab>Archived Documents</Tab>}
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Text fontSize="2xl" fontFamily="heading" fontWeight="light" mt={'2rem'}>Manage Display Name</Text>
                                    <Stack
                                        spacing={12}
                                        width={'40%'}
                                        my={12}
                                        mr={"30rem"}>
                                        <FormControl >
                                            <FormLabel>Display Name</FormLabel>
                                            <Input
                                                style={{ opacity: '1', cursor: 'default' }}
                                                type="email"
                                                value={displayName}
                                                variant={"filled"}
                                                disabled={userIsDisabled}
                                                onChange={(e) => setDisplayName(e.target.value)}
                                            />
                                        </FormControl>
                                        <Stack spacing={6} direction={['column', 'row']}>
                                            {userIsDisabled ?
                                                <Button w="10rem" colorScheme={"purple"} onClick={() => setUserIsDisabled(false)}>Edit Info</Button> :
                                                <Button w="10rem" colorScheme={"green"} onClick={handleSaveUser}>Save Info</Button>
                                            }
                                        </Stack>
                                    </Stack>
                                </TabPanel>
                                {/* HR - render invite_users modal depending on user's role */}
                                {(user_role === "owner" || user_role === "admin") && <TabPanel>
                                    <ManageCompanyInfo styles={styles} />
                                </TabPanel>}
                                {(user_role === "owner" || user_role === "admin") && <TabPanel>
                                    <ManagePaymentTerms styles={styles} />
                                </TabPanel>}
                                {(user_role === "owner" || user_role === "admin") && <TabPanel>
                                    <ManageTermsAndConditions styles={styles} />
                                </TabPanel>}
                                {(user_role === "owner" || user_role === "admin") && <TabPanel>
                                    <Text fontSize="2xl" fontFamily="heading" fontWeight="light" mt={'2rem'}>Manage Users</Text>
                                    <TanTable columns={userColumns} defaultData={users} type="contactModal" />
                                    <Button
                                        bg={'green.500'}
                                        color={'white'}
                                        w="20%"
                                        _hover={{
                                            bg: 'green.600',
                                        }}
                                        onClick={onInviteOpen}
                                    >
                                        Invite Users
                                    </Button>
                                    <InviteUsersModal isOpen={isInviteOpen} onClose={onInviteClose} />
                                </TabPanel>}
                                {/* HR - render archived_documents depending on user's role */}
                                {(user_role === "owner" || user_role === "admin") && <TabPanel>
                                    <Text fontSize="2xl" fontFamily="heading" fontWeight="light" mt={'2rem'}>Manage Archived Documents</Text>
                                    <TanTable columns={archivedDocumentColumns} defaultData={archived_documents} type="contactModal" />
                                </TabPanel>}
                            </TabPanels>
                        </Tabs>
                    </CardBody>
                </Card>
            </Flex >
        </>
    )
}