import { Button, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, FormControl, FormLabel, Input, ModalFooter, List, ListItem, HStack, Container, ListIcon } from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addInviteUser, getInviteUsers } from '../../redux/InviteUser/inviteUserCalls'
import { nanoid } from 'nanoid'

export default function InviteUsersModal({ isOpen, onClose, setCompletedTasks, completedTasks }) {
    const dispatch = useDispatch();
    const [newInviteUser, setNewInviteUser] = useState("")
    const { invite_users } = useSelector((state) => state.inviteUsers)
    const { addInviteUserProgress } = useSelector((state) => state.inviteUsers)
    const { account_id } = useSelector((state) => state.account)
    const { account_name } = useSelector((state) => state.account)

    // HR - function called on form submit, if primary fields are not empty, send data to backend and reset state
    // this function will also change the state of "invite_users" to true, allowing a green check to appear
    function handleInviteUser(e) {
        e.preventDefault()
        if (newInviteUser != "") {
            addInviteUser({ user: newInviteUser, account_id: account_id, access_key: nanoid(10), account_name: account_name }, dispatch)
        }
        setNewInviteUser("")
        if (completedTasks) {
            setCompletedTasks({ ...completedTasks, invite_users: true })
        }
    }

    function handleEmailChange(e) {
        setNewInviteUser(e.target.value)
    }

    // HR - make backend call to get the current list of invited users, which is displayed in the modal
    useEffect(() => {
        getInviteUsers(account_id, dispatch)
    }, [dispatch, addInviteUserProgress])

    // TODO - fix ObjectID matching to get invite users on first render


    return (
        <>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleInviteUser}>
                        <ModalHeader>Invite new users</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <HStack spacing={"2rem"}>
                                <FormControl isRequired>
                                    <FormLabel>Enter Email</FormLabel>
                                    <Input placeholder='email' name="" value={newInviteUser} onChange={handleEmailChange} />
                                </FormControl>
                                <Button colorScheme='green' type="submit" mt={8}>
                                    Invite
                                </Button>
                            </HStack>
                        </ModalBody>
                    </form>

                    <ModalFooter>
                        <Container centerContent my={2}>
                            <List spacing={3}>
                                {/* HR - map inviuteUsers to display with green check */}
                                {invite_users && invite_users.map((user, index) => {
                                    return (
                                        <ListItem key={user._id}>
                                            <ListIcon as={MdCheckCircle} color='green.500' />
                                            {user.email}</ListItem>
                                    )
                                })}
                            </List>
                        </Container>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
