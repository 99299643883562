import {
    Box, Flex, HStack, Icon, useColorModeValue, Text, useDisclosure, Menu, MenuButton, MenuItem, MenuList, Spacer
} from "@chakra-ui/react"
import {
    FiUsers, FiDollarSign, FiChevronDown, FiPackage, FiMail
} from "react-icons/fi"
import { NavLink, useNavigate } from "react-router-dom"
import ColorModeToggle from "./ThemeButton"
import { useDispatch } from "react-redux"
import { login } from "../redux/Auth/authCalls"
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from "./GoogleButton"
import Footer from "./Footer"


// HR - signed-out drawer icons
const NoUserItems = [
    { name: "Features", link: "/features", icon: FiPackage },
    { name: "Pricing", link: "/pricing", icon: FiDollarSign },
    { name: "About Us", link: "/about", icon: FiUsers },
    { name: "Contact Us", link: "/contactus", icon: FiMail },
]

const NavItem = ({ icon, children, ...rest }) => { // each of the sidebar buttons
    return (
        <Box
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
        >
            <Flex
                align="center"
                p="3"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    color: "cyan.400"
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: "cyan.400"
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    )
}

const MobileNav = ({ onOpen, ...rest }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    var LinkItems = NoUserItems

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            login(tokenResponse.access_token, dispatch)
            navigate("/")
        }
    })

    return (
        <Flex
            ml={0}
            px={4}
            height="20"
            alignItems="center"
            bg={useColorModeValue("white", "gray.900")}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue("gray.200", "gray.700")}
            {...rest}
        >
            <NavLink to="/" style={{ marginLeft: '2rem', marginRight: '2rem' }} >
                <Text
                    display="flex"
                    fontSize="2xl"
                    fontFamily="monospace"
                    fontWeight="bold"
                >
                    RapidToolz
                </Text>
            </NavLink>
            <HStack spacing={4}>
                {
                    LinkItems.map((link, index) => (
                        <NavLink key={index} to={link.link} >
                            <NavItem icon={link.icon} >
                                {link.name}
                            </NavItem>
                        </NavLink>
                    ))
                }
            </HStack>
            <Spacer />
            <HStack spacing={4}>
                <ColorModeToggle />
                <Menu>
                    <MenuButton
                        px={4}
                        py={2}
                        transition='all 0.2s'
                        borderRadius='md'
                        borderWidth='1px'
                    >
                        <HStack><Text>Sign Up</Text><FiChevronDown /></HStack>
                    </MenuButton>
                    <MenuList>
                        <NavLink to="/signup/company"><MenuItem>Sign Up Company</MenuItem></NavLink>
                        <NavLink to="/signup/user"><MenuItem>Sign Up User</MenuItem></NavLink>
                    </MenuList>
                </Menu>
                <GoogleButton onClickFunction={googleLogin} phrase={"Login"} />
            </HStack>
        </Flex>
    )
}

const SidebarWithHeader = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box>
            <MobileNav onOpen={onOpen} />
            {/* <Footer /> */}
        </Box>
    )
}

export default SidebarWithHeader
