

export default function tablesHelperPDF(tables, products) {
    const tableKeys = Object.keys(tables)
    var returnTables = {}
    // HR - loop through table object arrays
    tableKeys.forEach((tableKey) => {
        returnTables[tableKey] = []
        tables[tableKey].forEach(item => {
            // HR - for each item in array, grab product_id, find associated product object and push to new returnTable dictionary
            var itemID = item.split(":::")[1].replace(/[{()}]/g, '')
            returnTables[tableKey].push(products.filter((product) => product.product_id === itemID)[0])
        })
    })
    return returnTables
}