import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TermsTableRow from "./TermsTableRow";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginVertical: 10
    },
});

const TermsTable = ({ fob, payment, delivery }) => (
    <View style={styles.tableContainer}>
        {/*<TableHeader />*/}
        {/* HR - one row for headers, one row for ALL data, which is mapped in TableRow */}
        <TermsTableRow style={styles.content} termTitle={'F.O.B.'} termDetails={fob}/>
        <TermsTableRow style={styles.content} termTitle={'Payment Terms'} termDetails={payment} />
        <TermsTableRow style={styles.content} termTitle={'Delivery'} termDetails={delivery} />
        {/*<TableFooter items={data.items} />*/}
    </View>
);

export default TermsTable;
