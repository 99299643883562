import TableCell from "../../TableCell"
import OpenCellAdmin from "../../OpenCellAdmin"

// HR - historyColumns shows document data and contains OpenCell at the tail of the row to open document for editing (not currently functional)

const archivedDocumentColumns = [
    {
        header: "Document #",
        id: "doc_number",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "doc_number",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Type",
        id: "type",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "type",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Date Created",
        id: "date_created",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "date_created",
        type: "date",
        filterFn: "fuzzy"
    },
    {
        header: "Company Name",
        id: "contact.company_name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "contact.company_name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Actions",
        id: "actions",
        cell: ({ row, table }) => {
            return (
                <OpenCellAdmin row={row} table={table} from={'settings'} />
            )
        },
    }
]

export default archivedDocumentColumns