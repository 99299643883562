import TableCell from "../../TableCell"
import EditCell from "../../EditCell"

// HR - contact columns displays contact data and has an edit cell at the tail of the row, allowing the user to edit and delete rows

const userColumns = [
    {
        header: "User Email",
        id: "email",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} disableCell={true} tableType={"user"} />
            )
        },
        accessorKey: "email",
        type: 'text',
        filterFn: "fuzzy",
    },
    {
        header: "Role",
        id: "role",
        type: "select",
        options: [{ value: "owner", label: "owner" }, { value: "admin", label: "admin" }, { value: "user", label: "user" }],
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"user"} />
            )
        },
        accessorKey: "role",
        filterFn: "fuzzy"
    },
    {
        header: "Actions",
        id: "actions",
        cell: ({ row, table }) => {
            return (
                <EditCell row={row} table={table} tableType={"user"} />
            )
        },
    }
]

export default userColumns