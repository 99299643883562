import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import SortableItem from "./SortableItem";
import {
  Box,
  Button,
  HStack
} from "@chakra-ui/react";
import "../../styles/styles.css";
import { nanoid } from 'nanoid'
import { useDispatch } from "react-redux";
import SortableForm from "./SortableForm";


const SortableList = ({
  items,
  setItems,
  onImageUpload,
  handleFileChange,
  onTableUpdate,
  changeViewPDF,
  type,
  isOpen,
  onClose,
  onOpen,
  mode
}) => {
  const sortable = {
    sensors: useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    ),
    handleRemove: (id) => {
      setItems((items) => items.filter((item) => item.id !== id));
    },
    handleDragEnd: (event) => {
      const { active, over } = event;
      // HR - if we drag a sortable item over another, we determine our old and new indicies, and send them to dndKit's arrayMove() function 
      if (active.id !== over.id) {
        setItems((items) => {
          var oldIndex = items.findIndex(function (item) {
            return item.id === active.id;
          });
          var newIndex = items.findIndex(function (item) {
            return item.id === over.id;
          });
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    },
    handleAdd: (id) => {
      let newObj = { id: nanoid(), type: "string", data: id, ref: null, style: textStyle };
      setItems((items) => [...items, newObj]);
    },
    handleUpdateStart: (item) => {
      // HR - this is triggered when user clicks edit button on an item
      // this opens add content drawer and autocompletes input and style
      // editId is used to track the current item being edited
      if (item.type === "string") {
        setInputType("text")
        setAddInput(item.data)
        setTextStyle(item.style)
      } else {
        setInputType(item.type)
        setSpaceValue(Number(item.style))
      }
      setInputMode("Update")
      onOpen()
      setEditId(item.id)
    },
    handleUpdateEnd: (newVal) => {
      // HR - this is triggered when user clicks "submit" after editing an item (this is known by checking inputMode)
      // item whose id matches editId has data updated to value of addInput or spaceValue depending on type
      setItems(items.map((newItem) => {
        if (newItem.id === editId) {
          if(typeof newVal === "string"){
            return { ...newItem, data: newVal, style: textStyle }
          }else if(typeof newVal === "number"){
            return { ...newItem, data: `Space (${newVal})`, style: newVal }
          }
        } else {
          return newItem
        }
      }))
      setInputMode("Add")
    },
  };


  const [confirmVisible, setConfirmVisible] = useState(false);
  const [addInput, setAddInput] = useState("");
  const [editId, setEditId] = useState("");
  const [textStyle, setTextStyle] = useState("Default Text");
  const [inputMode, setInputMode] = useState("Add")
  const [inputType, setInputType] = useState("text");
  const [spaceValue, setSpaceValue] = useState(1)
  const dispatch = useDispatch()

  const addData = {
    button: {
      onChange: (e) => {
        const { value } = e.target;
        if (value) {
          setConfirmVisible(true);
        } else {
          setConfirmVisible(false);
        }
        setAddInput(value);
      },
      // HR confirm() is called when user clicks "submit"
      confirm: () => {
        if (inputType === "text") {
          if (addInput === "") {
            return;
          }
          sortable.handleAdd(addInput); // add input to item array to be displayed in sortable list
          setAddInput(""); // reset input
        } else if (inputType === "space") {
          var newSpace = {
            data: `Space (${spaceValue})`,
            id: nanoid(21),
            style: spaceValue,
            type: "space"
          }
          setItems(oldItems => [...oldItems, newSpace])
        }
        // else if (addInput.includes("blob")) {
        //   // check if input is image (data is reference to image)
        //   onImageUpload(addInput);
        // }
        // confirm visible used to alter appearance/disable submit button until user entered text input (could submit data when not empty)
        setConfirmVisible(false);
      },
    },
  };

  const updateData = {
    button: {
      onChange: (e) => {
        const { value } = e.target;
        if (value) {
          setAddInput(value);
        }
      },
      // HR confirm() is called when user clicks "submit"
      confirm: () => {
        if (inputType === "text") {
          if (addInput === "") {
            return;
          }        
          sortable.handleUpdateEnd(addInput); // update input in item array to be displayed in sortable list
          setAddInput(""); // reset input
        } else if (inputType === "space") {
          sortable.handleUpdateEnd(Number(spaceValue)); // update spaceValue in item array to be displayed in sortable list
          setSpaceValue(1); // reset input
        }
        setEditId("")
        // confirm visible used to alter appearance/disable submit button until user entered text input (could submit data when not empty)
        setConfirmVisible(false);
      },
    },
  };

  // HR - this function is called whenever user clicks "x" to close drawer
  function resetContent() {
    setAddInput("")
    setInputMode("Add")
    setTextStyle("Default Text")
  }

  // HR - Majority of this is pulled from dnd-kit, check documentation for further information
  return (
    <Box style={{ background: "none", height: '100%', padding: '25px' }}>
      <DndContext modifiers={[restrictToFirstScrollableAncestor]} collisionDetection={closestCenter} sensors={sortable.sensors} onDragEnd={sortable.handleDragEnd}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <Box style={{ background: "linear-gradient(#3182ce, #89bef1, #89bef1, #3182ce)", opacity: 0.85, borderRadius: "10px", border: "solid #4A5568 1px", height: '100%', padding: 10 }}>
            <div style={{ overflowY: "auto", height: "100%" }} >
              {/* HR - each object from items array is mapped into a SortableItems */}
              {items.map((item, index) => {
                return (
                  <SortableItem
                    key={index}
                    id={item.id} // when transition/transform stops working in sortableitem, it has historically been this id that was wrong (used to be id={item})
                    item={item}
                    onRemove={sortable.handleRemove}
                    onUpdate={sortable.handleUpdateStart}
                    onTableUpdate={onTableUpdate}
                  />
                );
              })}
            </div>
          </Box>
          {/* HR - when user clicks "add" button, we show the form which allows them to submit text, image, or table */}
          <SortableForm addInput={addInput} resetContent={resetContent} addData={addData} updateData={updateData} confirmVisible={confirmVisible} isOpen={isOpen} onClose={onClose}
            onOpen={onOpen} textStyle={textStyle} setTextStyle={setTextStyle} inputMode={inputMode} inputType={inputType} setInputType={setInputType}
            spaceValue={spaceValue} setSpaceValue={setSpaceValue} />
          <HStack justifyContent={'center'} m={'1rem'} spacing={'2rem'}>
            <Button onClick={() => { resetContent(); onOpen(); }} colorScheme={"green"}>Add Content</Button>
            <Button colorScheme="orange" onClick={changeViewPDF}>Hide/Show PDF</Button>
          </HStack>
        </SortableContext>
      </DndContext>
    </Box>
  );
};
export default SortableList;
