import paymentTermsColumns from "../TanStackTable/Columns/Admin/paymentTermsColumns";
import AddPaymentTermsPopup from "../AddPaymentTermsPopup";
import TanTable from "../TanStackTable/TanTable"
import { useDisclosure, Button, VStack, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default function ManagePaymentTerms(styles) {
    const { payment_terms } = useSelector((state) => state.paymentTerms)
    const { isOpen: isTermsOpen, onOpen: onTermsOpen, onClose: onTermsClose } = useDisclosure()
    return (
        <>
            <VStack justifyContent={'center'} mx={'2rem'}>
            <Text fontSize="2xl" fontFamily="heading" fontWeight="light" mt={'2rem'}>Manage Payment Terms</Text>
                <TanTable columns={paymentTermsColumns} defaultData={payment_terms} type="paymentModal" />
                <Button
                    bg={'green.500'}
                    color={'white'}
                    w="20%"
                    _hover={{
                        bg: 'green.600',
                    }}
                    onClick={onTermsOpen}
                >
                    Add Payment Term
                </Button>
            </VStack>
            <AddPaymentTermsPopup isOpen={isTermsOpen} onClose={onTermsClose} /></>
    )
}